import s from "@mobiltracker/schema-validation";

export type CurrentRoute = {
  executedRouteId: string;
  vanId: number;
  createDate: string;
  routeTemplateId: number | null;
  routeTemplateName: string | null;
};

export const CurrentRoutesSchema = s.array(
  s
    .object({
      executedRouteId: s.string(),
      vanId: s.number(),
      createDate: s.string(),
      routeId: s.number().nullable(),
      routeTemplateName: s.string().nullable(),
    })
    .transform(({ routeId, ...rest }) => ({
      routeTemplateId: routeId,
      ...rest,
    }))
);
