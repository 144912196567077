import React from "react";

export const lazyPages = {
  LoginPage: React.lazy(() => import("src/screens/Login/LoginScreen")),

  ActivationPage: React.lazy(
    () => import("src/screens/ActivationPage/AccountActivationPage")
  ),
  RecoverPasswordPage: React.lazy(
    () => import("src/screens/RecoverPassword/RecoverPasswordScreen")
  ),
  ResetPasswordPage: React.lazy(
    () => import("src/screens/RecoverPassword/ResetPasswordScreen")
  ),

  DevPage: React.lazy(() => import("src/screens/Dev/DevPage")),
  HomePage: React.lazy(() => import("src/screens/HomePage/HomePage")),

  ProfilePage: React.lazy(() => import("src/screens/Profile/ProfileScreen")),

  ListExecutedRoutesPage: React.lazy(
    () => import("src/screens/HistoryRoutes/ExecutedRoutesScreen")
  ),
  ListCurrentRoutesPage: React.lazy(
    () => import("src/screens/CurrentRoutes/CurrentRoutesScreen")
  ),
  ViewExecutedRoutePage: React.lazy(
    () => import("src/screens/HistoryRoutes/ExecutedRouteScreen")
  ),

  CreateRecurrencePage: React.lazy(
    () =>
      import("src/screens/Recurrences/CreateRecurrence/CreateRecurrencePage")
  ),
  ListRecurrencesPage: React.lazy(
    () => import("src/screens/Recurrences/ListRecurrencesPage")
  ),

  ListInstallmentsByMonthPage: React.lazy(
    () => import("src/screens/Installments/InstallmentsByMonthPage")
  ),

  ListInstallmentsByParentPage: React.lazy(
    () => import("src/screens/Installments/InstallmentsByParent")
  ),

  ListInstallments: React.lazy(
    () => import("src/screens/Installments/InstallmentsScreen")
  ),

  ListRoutesPage: React.lazy(() => import("src/screens/Routes/RoutesScreen")),
  CopyRoutePage: React.lazy(() => import("src/screens/Routes/CopyRouteScreen")),
  ViewRoutePage: React.lazy(() => import("src/screens/Routes/ViewRoutePage")),
  EditRoutePage: React.lazy(
    () => import("src/screens/Routes/EditRoute/EditRoutePage")
  ),

  CreateRoutePage: React.lazy(
    () => import("src/screens/Routes/NewRouteScreen")
  ),
  CreateRouteItemsPage: React.lazy(
    () => import("src/screens/Routes/BuildRoutePage")
  ),

  ListVansPage: React.lazy(() => import("src/screens/Vans/ListVansScreen")),
  VanPage: React.lazy(() => import("src/screens/Vans/VanScreen/VanScreen")),
  CreateVanPage: React.lazy(() => import("src/screens/Vans/NewVanScreen")),
  ListStudentsPage: React.lazy(
    () => import("src/screens/Students/StudentsScreen")
  ),
  EditStudentPage: React.lazy(
    () => import("src/screens/Students/EditStudentScreen/EditStudentScreen")
  ),
  CreateStudentPage: React.lazy(
    () => import("src/screens/Students/NewStudentScreen")
  ),

  ListParentsPage: React.lazy(
    () => import("src/screens/Parents/ParentsScreen")
  ),
  EditParentPage: React.lazy(
    () => import("src/screens/Parents/ParentPage/ParentPage")
  ),
  CreateParentPage: React.lazy(
    () => import("src/screens/Parents/NewParentScreen")
  ),
  ListSchoolsPage: React.lazy(
    () => import("src/screens/Schools/SchoolsScreen")
  ),
  EditSchoolPage: React.lazy(
    () => import("src/screens/Schools/EditSchoolScreen")
  ),
  CreateSchoolPage: React.lazy(
    () => import("src/screens/Schools/NewSchoolScreen")
  ),

  ListDriversPage: React.lazy(
    () => import("src/screens/Drivers/DriversScreen")
  ),
  DriverPage: React.lazy(
    () => import("src/screens/Drivers/DriverPage/DriverPage")
  ),
  CreateDriverPage: React.lazy(
    () => import("src/screens/Drivers/NewDriverScreen")
  ),

  SubscriptionPage: React.lazy(
    () => import("src/screens/Subscription/SubscriptionPage")
  ),

  FakeAuthPage: React.lazy(() => import("src/screens/FakeAuth/FakeAuthScreen")),

  DriverResources: React.lazy(
    () => import("src/screens/Resources/DriverResources")
  ),
  ParentResources: React.lazy(
    () => import("src/screens/Resources/ParentResources")
  ),
};
