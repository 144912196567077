import s from "@mobiltracker/schema-validation";

export const ParentSchema = s.object({
  id: s.string(),
  name: s.string(),
  phone: s.string(),
  accessCode: s.string(),
});

export const PagedParentSchema = s.object({
  id: s.string(),
  name: s.string(),
  phone: s.string(),
  accessCode: s.string().nullable(),
  students: s.string().array(),
  schools: s.string().array(),
});
