import React from "react";
import Main from "./Main";

import "./style/ds-styles.scss";
import "./style/bootstrap-5.scss";

const App: React.FC = () => {
  return (
    <>
      <Main />
    </>
  );
};

export default App;
