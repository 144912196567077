import { getKeys } from "@mobiltracker/stronger-js-typing";
import { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { NavigationProps, Route } from "../infrastructure/routes";

export function useAppNavigate() {
  const navigate = useNavigate();

  return useCallback(
    <URLProps, ComponentProps>(
      route: Route<URLProps, ComponentProps>,
      props: MakeOptional<NavigationProps<URLProps, ComponentProps>>
    ) => {
      const unwrappedProps = unwrapProps(props);
      const path = buildHref(route, unwrappedProps);

      navigate(path, {
        state: unwrappedProps.componentProps,
      });
    },
    [navigate]
  );
}

function buildHref<URLProps, ComponentProps>(
  route: Route<URLProps, ComponentProps>,
  props: NavigationProps<URLProps, ComponentProps>
) {
  // Build parameters object to form the final URL
  let urlParams: { [key: string | number | symbol]: string } = {};
  if (props.urlProps !== undefined) {
    getKeys(props.urlProps).forEach((key) => {
      if (props.urlProps[key] === undefined) {
        return;
      }

      const paramName = key;
      const paramValue = String(props.urlProps[key]);
      urlParams[paramName] = paramValue;
    });
  }
  return generatePath(route.path, urlParams);
}

function unwrapProps<URLProps, ComponentProps>(
  props: MakeOptional<NavigationProps<URLProps, ComponentProps>>
): NavigationProps<URLProps, ComponentProps> {
  // @ts-ignore Most routes receive undefined as data (since they don't need anything from other components or the
  // URL), so we use MakeOptional to make writing cose more pleasant, otherwise you'd have to type
  // navigate(routes.homePage, { urlParams: undefined }) instead of navigate(routes.homePage, {}).
  // Typescript is just not smart enough to realise that props.urlProps will never be undefined unless the page
  // actually expects undefined.
  return {
    urlProps: undefined,
    componentProps: undefined,
    ...props,
  };
}

/**
 * Marks fields that can receive "undefined" as optional.
 *
 * @example MakePartial<{ bar: undefined|string; baz: number }, "bar"> === { bar?: undefined|string; baz: number; } // Notice the extra "?"
 */
type MakeOptional<T> = { [key in UndefineableKeyNames<T>]?: T[key] } & {
  [key in NonUndefineableKeyNames<T>]: T[key];
};

type UndefineableKeyNames<T> = {
  [key in keyof T]: undefined extends T[key] ? key : never;
}[keyof T];

type NonUndefineableKeyNames<T> = {
  [key in keyof T]: undefined extends T[key] ? never : key;
}[keyof T];
