import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import { configureStoreTyped } from "./configureStoreTyped";
import rootReducer from "../reducers";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["login", "fakeAuth"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStoreTyped(persistedReducer);
export const persistor = persistStore(store);
