import s from "@mobiltracker/schema-validation";
import { driverResponseSchema } from "./DriverSchema";
import { basicExecutedRouteSchema } from "./ExecutedRouteSchemas";
import { ownerRoutesListResponseSchema } from "./RoutesListSchemas";
import { StudentResponseSchema } from "./StudentSchemas";
import { VansResponseSchema } from "./VansSchemas";
import { PagedParentSchema } from "./ParentsSchemas";
import { SchoolReponseSchema } from "./SchoolSchemas";

export const ExecutedRoutePagedItemSchema = s.object({
  totalPages: s.number(),
  items: basicExecutedRouteSchema.array(),
});

export const VansPagedItemSchema = s.object({
  totalPages: s.number(),
  items: VansResponseSchema.array(),
});

export const OwnerRoutesListPagedItemSchema = s.object({
  totalPages: s.number(),
  items: ownerRoutesListResponseSchema.array(),
});

export const DriverPagedItemSchema = s.object({
  totalPages: s.number(),
  items: driverResponseSchema.array(),
});

export const StudentsPagedItemSchema = s.object({
  totalPages: s.number(),
  items: StudentResponseSchema.array(),
});

export const ParentsPagedItemSchema = s.object({
  totalPages: s.number(),
  items: PagedParentSchema.array(),
});

export const SchoolsPagedItemSchema = s.object({
  totalPages: s.number(),
  items: SchoolReponseSchema.array(),
});
