import s from "@mobiltracker/schema-validation";

const installmentSummaryLabelSchema = s
  .union([
    s.literal("TOTAL"),
    s.literal("PAID"),
    s.literal("PENDING"),
    s.literal("LATE"),
  ])
  .or(s.any().transform(() => "UNKNOWN" as const));

export const InstallmentSchema = s.object({
  recurringPaymentId: s.string().uuid(),
  parentName: s.string(),
  parentId: s.string().uuid(),
  parentPhoneNumber: s.string(),
  installmentNumber: s.number().int(),
  description: s.string(),
  status: s.union([
    s.literal("PENDING"),
    s.literal("PAID"),
    s.literal("LATE"),
    s.literal("FORGIVEN"),
  ]),
  dueValue: s.number(),
  dueDate: s.string().datetime(),
});

export const GetInstallmentsResponseSchema = s.object({
  installments: InstallmentSchema.array(),
  summaries: s
    .object({
      label: installmentSummaryLabelSchema,
      sum: s.number(),
      quantity: s.number(),
    })
    .array(),
});
