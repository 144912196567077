import s from "@mobiltracker/schema-validation";

export type Van = {
  vanId: number;
  name: string;
  mtkIntegration: { isActive: boolean } | null;
  driverIds: string[];
};

export const VansSchema = s.array(
  s.object({
    vanId: s.number(),
    name: s.string(),
    mtkIntegration: s.union([
      s.null(),
      s.object({
        isActive: s.boolean(),
      }),
    ]),
    driverIds: s.array(s.string()),
  })
);
