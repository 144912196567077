import s from "@mobiltracker/schema-validation";

export const getSuggestionsResponseSchema = s
  .object({
    suggestionId: s.string(),
    description: s.string(),
  })
  .array();

export type PostSuggestionResponse = {
  suggestionId: string;
  response: "YES" | "NO" | "IGNORE";
};
