import s from "@mobiltracker/schema-validation";

export const GetParentsResponseSchema = s
  .object({
    userId: s.string().uuid(),
    name: s.string(),
    phoneNumber: s.string(),
    ticket: s.string(),
    students: s.number().array(),
    email: s.string().nullable(),
    street: s.string().nullable(),
    addressNumber: s.string().nullable(),
    addressComplement: s.string().nullable(),
    state: s.string().nullable(),
    city: s.string().nullable(),
    district: s.string().nullable(),
    zipCode: s.string().nullable(),
  })
  .transform(
    ({
      street,
      addressNumber,
      addressComplement,
      state,
      city,
      district,
      zipCode,
      ...rest
    }) => {
      if (
        street === null ||
        addressNumber === null ||
        city === null ||
        state === null ||
        district === null ||
        zipCode === null
      )
        return { address: null, ...rest };

      return {
        address: {
          street,
          addressNumber,
          addressComplement,
          state,
          city,
          district,
          zipCode,
        },
        ...rest,
      };
    }
  )
  .array();
