import {
  createStore,
  applyMiddleware,
  Reducer,
  Action,
  Middleware,
  Dispatch,
} from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";
import vanescolaApi from "src/reducers/vanescolaApi";

export function configureStoreTyped<State, PlainAction extends Action<any>>(
  reducer: Reducer<State, PlainAction>
) {
  const typedThunk: ThunkMiddleware<State, PlainAction, never> = thunk;
  const typedApi: Middleware<
    Dispatch<PlainAction>,
    State,
    any
  > = vanescolaApi.middleware as any;

  return createStore(reducer, applyMiddleware(typedThunk, typedApi));
}
