import s from "@mobiltracker/schema-validation";

const basicInvoiceInfo = s.object({
  subscriptionId: s.number().nullable(),
  dueDate: s.coerce.date(),
  total: s.number(),
  type: s.enum(["ANNUAL_PAYMENT", "MONTHLY_PAYMENT", "SEMIANNUAL_PAYMENT"]),
  creationDate: s.coerce.date(),
});

export const InvoicesSchema = basicInvoiceInfo
  .and(
    s.union([
      s.object({
        status: s.literal("PAID"),
        paymentDate: s.coerce.date(),
        paymentMethod: s.enum([
          "DEPOSIT",
          "IUGU_BANK_SLIP",
          "IUGU_CREDIT_CARD",
          "IUGU_PIX",
          "PIX",
        ]),
      }),
      s.object({
        status: s.literal("PENDING"),
        paymentLink: s.string(),
      }),
      s.object({
        status: s.literal("LATE"),
        paymentLink: s.string(),
      }),
      s.object({
        status: s.literal("CANCELED"),
      }),
      s.object({
        status: s.literal("EXPIRED"),
        paymentLink: s.string().nullable(),
      }),
    ])
  )
  .array();
