import {
  TypedUseSelectorHook,
  useDispatch as useDispatchUntyped,
  useSelector as useSelectorUntyped,
} from "react-redux";

import { AppDispatch, RootState } from "./types";

export const useAppDispatch = () => useDispatchUntyped<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> =
  useSelectorUntyped;
