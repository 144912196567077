import { Icon } from "@mobiltracker/react-icons";
import React, { useRef, useState } from "react";
import { useRootClose } from "react-overlays";
import { useLocation } from "react-router-dom";
import { logoutAction } from "src/actions/login";
import { useAppNavigate } from "src/hooks/useAppNavigate";
import {
  useGetCompletedTutorialsQuery,
  useGetInvoicesQuery,
  useGetScopesQuery,
} from "src/reducers/vanescolaApi";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { ZIndex } from "src/style/zIndex";
import colors from "src/style/_export.scss";
import { Scopes } from "src/types/Scopes";
import { routes } from "../routes";
import { SideMenu, SideMenuItemProps } from "./SideMenu";
import logo from "../../img/logo.svg";

// TODO: think of a better way to set this height based on component size dynamically
export const NAVBARHEIGHT = "3.9em";

export function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();
  const url = useLocation();
  const ref = useRef(null);
  const userScopesQuery = useGetScopesQuery();
  const handleRootClose = () => setIsOpen(false);
  useRootClose(ref, handleRootClose, { disabled: !isOpen });

  const pendencies = useSelectPendencies();
  const invoices = useGetInvoicesQuery();

  const fakeAuth = useAppSelector((s) => s.fakeAuth);

  const items: (SideMenuItemProps & { scopes?: Scopes; path: string })[] = [
    {
      icon: "UserGraduate",
      title: "Estudantes",
      hasNotifications: pendencies.hasNoStudents,
      onClick: () => navigate(routes.listStudents, {}),
      path: routes.listStudents.path,
    },
    {
      icon: "UserGroup",
      title: "Responsáveis",
      onClick: () => navigate(routes.listParents, {}),
      path: routes.listParents.path,
    },
    {
      icon: "School",
      title: "Escolas",
      hasNotifications: pendencies.hasNoSchools,
      onClick: () => navigate(routes.listSchools, {}),
      path: routes.listSchools.path,
    },
    {
      icon: "IdCard",
      title: "Motoristas",
      onClick: () => navigate(routes.listDrivers, {}),
      path: routes.listDrivers.path,
      scopes: ["driver:read"],
    },
    {
      icon: "Van",
      title: "Vans",
      onClick: () => navigate(routes.listVans, {}),
      path: routes.listVans.path,
      scopes: ["driver:read", "vans:read"],
    },
    {
      icon: "Road",
      title: "Rotas",
      hasNotifications: pendencies.hasNoRoutes,
      onClick: () => navigate(routes.listRoutes, {}),
      path: routes.listRoutes.path,
    },
    {
      icon: "Subscription",
      title: "Meu Plano",
      onClick: () => navigate(routes.subscription, {}),
      path: routes.subscription.path,
      hasNotifications: invoices.data?.some(
        (i) =>
          i.status === "PENDING" ||
          i.status === "EXPIRED" ||
          i.status === "LATE"
      ),
      scopes: ["subscriptions:read"],
    },
    {
      icon: "User",
      title: "Minha conta",
      onClick: () => navigate(routes.profile, {}),
      path: routes.profile.path,
    },
  ];

  const isOnPage = (path: string) => url.pathname.startsWith(path);

  const menuItems = items
    .filter((item) => {
      const userScopes =
        userScopesQuery.isLoading ||
        userScopesQuery.isUninitialized ||
        userScopesQuery.isError
          ? []
          : userScopesQuery.data.scopes;

      return (
        item.scopes === undefined ||
        item.scopes.every((scope) => userScopes.includes(scope))
      );
    })
    .map((item) => ({ ...item, isActive: url.pathname === item.path }))
    .map(({ hasNotifications, ...rest }) => ({
      hasNotifications: hasNotifications && !isOnPage(rest.path),
      ...rest,
    }));

  return (
    <>
      <nav
        className="navbar sticky-top"
        style={{
          backgroundColor: colors.primary,
          zIndex: ZIndex.FIXED,
        }}
      >
        <div className="container">
          <button
            className="btn navbar-brand d-flex"
            onClick={() => navigate(routes.home, {})}
          >
            {fakeAuth.enabled ? (
              <div
                className="align-middle text-center"
                style={{ color: colors.white }}
              >
                <Icon name="Exclamation" /> Fake
              </div>
            ) : (
              <VanEscolaLogo />
            )}
          </button>
          <button
            className="btn btn-outline-light position-relative"
            onClick={() => setIsOpen((isOpen) => !isOpen)}
          >
            <span>
              {!isOpen ? <Icon name="Navicon" /> : <Icon name="Xmark" />}
            </span>
            {menuItems.some((i) => i.hasNotifications) && !isOpen && (
              <span
                style={{
                  position: "absolute",
                  transform: "translate(0.2em, -1em)",
                }}
              >
                <Icon name="Circle" color="red" size="xs" />
              </span>
            )}
          </button>
        </div>
      </nav>
      {isOpen && (
        <SideMenu
          items={menuItems}
          onClose={() => setIsOpen(false)}
          onLogout={() => {
            dispatch(logoutAction());
            navigate(routes.login, {});
          }}
        />
      )}
    </>
  );
}

function VanEscolaLogo() {
  return (
    <span
      style={{
        backgroundColor: colors.white,
        textAlign: "center",
        width: "6.5rem",
        height: "2.2rem",
        borderRadius: "20px 20px",
      }}
    >
      <img
        className="position-relative align-self-center"
        src={logo}
        height="18"
        alt=""
      />
    </span>
  );
}

function useSelectPendencies() {
  const tutorialsQuery = useGetCompletedTutorialsQuery();

  if (!tutorialsQuery.isSuccess) {
    return {
      hasNoSchools: true,
      hasNoStudents: true,
      hasNoRoutes: true,
    };
  }

  return {
    hasNoSchools: !tutorialsQuery.data.isSchoolTutorialCompleted,
    hasNoStudents: !tutorialsQuery.data.isStudentTutorialCompleted,
    hasNoRoutes: !tutorialsQuery.data.isRouteTutorialCompleted,
  };
}
