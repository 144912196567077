import { ThunkAction } from "./types";

export function loggedInAction(
  accessToken: string
): ThunkAction<Promise<void>> {
  return async (dispatch) =>
    dispatch({
      type: "LOGIN",
      data: { accessToken, authenticated: true },
    });
}

export function logoutAction(): ThunkAction<Promise<void>> {
  return async (dispatch) => {
    dispatch({
      type: "LOGOUT",
    });
  };
}
