import { Button } from "@mobiltracker/design-system/lib/atoms";
import React from "react";
import { useAppNavigate } from "src/hooks/useAppNavigate";
import { routes } from "src/infrastructure/routes";

export const Footer = () => {
  const navigate = useAppNavigate();

  return (
    <footer
      style={{
        padding: 12,
        backgroundColor: "rgb(248, 249, 250)",
      }}
      className="footer text-sm bottom text-center"
    >
      <span style={{ color: "rgba(0,0,0,.5)" }}>
        VanEscola {new Date().getFullYear()}
      </span>

      {process.env.NODE_ENV === "development" && (
        <div style={{ float: "right" }}>
          <Button onClick={() => navigate(routes.dev, {})}>Developer</Button>
        </div>
      )}
    </footer>
  );
};
