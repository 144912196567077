// indexes based on: https://getbootstrap.com/docs/4.6/layout/overview/#z-index
export enum ZIndex {
  DROPDOWN = 1000,
  STICKY = 1020,
  FIXED = 1030,
  MODAL_BACKDROP = 1040,
  MODAL = 1050,
  POPOVER = 1060,
  TOOLTIP = 1070,
}
