import s from "@mobiltracker/schema-validation";

const organizationInfoSchema = s.object({
  businessName: s.string().nullable(),
  cpfCnpj: s.string().nullable(),
  zipCode: s.string().nullable(),
  street: s.string().nullable(),
  addressNumber: s.string().nullable(),
  addressComplement: s.string().nullable(),
  district: s.string().nullable(),
  city: s.string().nullable(),
  state: s.string().nullable(),
});

export type OrganizationInfo = s.infer<typeof organizationInfoSchema>;

export const accountInfoSchema = s.object({
  name: s.string(),
  phoneNumber: s.string().nullable(),
  email: s.string().nullable(),
  organizationInfo: organizationInfoSchema.nullable(),
});

export type AccountInfo = s.infer<typeof accountInfoSchema>;
