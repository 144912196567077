import s from "@mobiltracker/schema-validation";

export const directionSchema = s.enum(["FROM", "TO"]);

const schoolRouteItem = s.object({
  schoolId: s.number(),
  studentId: s.null(),
});

const studentRouteItem = s.object({
  schoolId: s.null(),
  studentId: s.number(),
  direction: directionSchema,
});

export const routeItemSchema = s
  .object({
    order: s
      .number()
      .nullable()
      .transform((order) => order ?? 0),
    schedulerTime: s.string().nullable(),
  })
  .and(s.union([schoolRouteItem, studentRouteItem]));

export const daysSchema = s.object({
  mon: s.boolean(),
  tue: s.boolean(),
  wed: s.boolean(),
  thu: s.boolean(),
  fri: s.boolean(),
  sat: s.boolean(),
  sun: s.boolean(),
});

export const getRoutesResponseSchema = s
  .object({
    routeId: s.number(),
    name: s.string().nullable(),
    vanId: s.number(),
    schedulerTime: s.string().nullable(),
    routeItems: routeItemSchema.array(),
  })
  .and(daysSchema)
  .transform(({ mon, tue, wed, thu, fri, sat, sun, ...rest }) => ({
    days: { mon, tue, wed, thu, fri, sat, sun },
    ...rest,
  }))
  .transform(({ routeId, ...rest }) => ({ id: routeId, ...rest }))
  .array();
