import s, { InferSchema } from "@mobiltracker/schema-validation";

export type CheckVanCanBeDeleted = InferSchema<
  typeof CheckVanCanBeDeletedSchema
>;
export const CheckVanCanBeDeletedSchema = s.object({
  deletionOk: s.boolean(),
  drivers: s
    .object({
      id: s.string().uuid(),
      name: s.string(),
    })
    .array(),
  routes: s
    .object({
      name: s.string().nullable(),
      id: s.number(),
    })
    .array(),
  currentRoute: s
    .object({
      name: s.string().nullable(),
      id: s.string().uuid(),
    })
    .nullable(),
});
