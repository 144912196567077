import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppDispatch } from "src/store/hooks";
import { SliceActions } from "./types";

type FakeAuthState =
  | {
      enabled: false;
    }
  | {
      enabled: true;
      token: string;
      apiUrl: string;
    };

const initialState: FakeAuthState = { enabled: false } as FakeAuthState;

const slice = createSlice({
  initialState,
  name: "fakeAuth",
  reducers: {
    enableFakeAuth(
      state,
      action: PayloadAction<{ token: string; apiUrl: string }>
    ) {
      return {
        enabled: true,
        token: action.payload.token,
        apiUrl: action.payload.apiUrl,
      };
    },

    disableFakeAuth() {
      return {
        enabled: false,
      };
    },
  },
});

export type FakeAuthActions = SliceActions<typeof slice>;
export default slice.reducer;

export function useFakeAuth() {
  const dispatch = useAppDispatch();
  return {
    enableFakeAuth(token: string, apiUrl: string) {
      dispatch({
        type: "LOGIN",
        data: { accessToken: "FAKE", authenticated: true },
      });
      dispatch(slice.actions.enableFakeAuth({ token, apiUrl }));
    },
    disableFakeAuth() {
      dispatch({
        type: "LOGOUT",
      });
      dispatch(slice.actions.disableFakeAuth());
    },
  };
}
