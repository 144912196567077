import s from "@mobiltracker/schema-validation";

export const GetStudentsResponseSchema = s
  .object({
    studentId: s.number(),
    name: s.string(),
    parents: s.string().uuid().array(),
    school: s.number(),
    isActive: s.boolean(),
  })
  .transform((s) => ({
    id: s.studentId, // Rename studentId to id
    name: s.name,
    parents: s.parents,
    school: s.school,
    isActive: s.isActive,
  }))
  .array();
