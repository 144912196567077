import s from "@mobiltracker/schema-validation";

export type QueryOptionsEndpoint =
  | "owner/drivers/query-options"
  | "owner/routes/history/query-options"
  | "owner/vans/query-options"
  | "owner/routes/list/query-options"
  | "owner/students/query-options"
  | "owner/parents/query-options"
  | "owner/schools/query-options";

export const ResourceQueryOptionsSchema = s.object({
  filterOptions: s.array(
    s.object({
      id: s.string(),
      label: s.string(),
      options: s.object({ label: s.string(), id: s.string() }).array(),
    })
  ),
  orderingOptions: s
    .object({
      id: s.string(),
      label: s.string(),
    })
    .array(),
});
