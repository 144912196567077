import { Icon } from "@mobiltracker/react-icons";
import { AppIcon } from "@mobiltracker/react-icons/lib/icons";
import React from "react";
import { ZIndex } from "src/style/zIndex";
import colors from "src/style/_export.scss";

export type SideMenuItemProps = {
  icon: AppIcon["name"];
  title: string;
  onClick: () => void;
  hasNotifications?: boolean;
  isActive?: boolean;
};

type SideMenuProps = {
  items: SideMenuItemProps[];
  onClose?: () => void;
  onLogout: () => void;
};

export function SideMenu({ items, onClose, onLogout }: SideMenuProps) {
  return (
    <>
      <div
        style={{
          display: "block",
          zIndex: ZIndex.FIXED,
          width: "100%",
          height: "100%",
          position: "absolute",
        }}
        onClick={onClose}
      ></div>
      <div
        className="overflow-auto d-flex pl-3 pb-4 flex-column"
        style={{
          zIndex: ZIndex.FIXED,
          position: "fixed",
          right: 0,
          width: "15rem",
          height: "100vh",
          background: colors.primary,
          boxShadow: "-5px 4px 5px rgba(0, 0, 0, 0.2)",
        }}
      >
        {items.map((item, i) => (
          <div className="mt-4" key={i}>
            <MenuItem {...item} />
          </div>
        ))}
        <div className="mt-4">
          <button className="btn card-link" onClick={onLogout}>
            <span className="h5" style={{ color: colors.white }}>
              <span>Sair</span>
            </span>
          </button>
        </div>
      </div>
    </>
  );
}

function MenuItem({
  icon,
  title,
  onClick,
  hasNotifications,
  isActive,
}: SideMenuItemProps) {
  const navItem = (
    <button className="btn card-link" onClick={onClick}>
      <span
        className={`h5 ${isActive ? "active font-weight-bold" : ""}`}
        style={{ color: colors.white }}
      >
        <span className="pe-2">
          <Icon name={icon} />
        </span>
        {title}
      </span>
      {hasNotifications && <NotificationIcon />}
    </button>
  );

  return navItem;
}

function NotificationIcon() {
  return (
    <span
      style={{
        position: "absolute",
        transform: "translate(0.2em, -0.5em)",
      }}
    >
      <Icon name="Circle" color="red" size="xs" />
    </span>
  );
}
