import { getKeys } from "@mobiltracker/stronger-js-typing";
import { matchRoutes, useLocation } from "react-router-dom";
import { routes } from "src/infrastructure/routes";
import { filterNulls } from "src/utils/filterNulls";

export function useBreadcrumbs() {
  const { pathname } = useLocation();

  const paths = trimEndSlash(pathname)
    .split("/")
    .map((_, index, pathSegments) =>
      pathSegments.slice(0, index + 1).join("/")
    );

  const routeDefinitions = getKeys(routes).map((key) => ({
    path: routes[key].path,
    definition: routes[key],
  }));

  const matches = paths
    .map((path) => matchRoutes(routeDefinitions, path))
    .map((m) => (m !== null ? m[0] : null))
    .filter(filterNulls);

  const matchesWithRoutes = matches
    .map((match) => {
      const route = routeDefinitions.find((r) => r.path === match.route.path);

      if (route === undefined) {
        return null;
      }

      return {
        link: match.pathname,
        route: route.definition,
      };
    })
    .filter(filterNulls);

  return matchesWithRoutes;
}

function trimEndSlash(str: string) {
  return str.endsWith("/") ? str.slice(0, str.length - 1) : str;
}
