import { LoadingAlert } from "@mobiltracker/design-system/lib/widgets";
import { getKeys } from "@mobiltracker/stronger-js-typing";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link, Navigate, Route, Routes, useParams } from "react-router-dom";
import { useBreadcrumbs } from "src/hooks/useBreadcrumbs";
import { useIsWebview } from "src/hooks/useIsWebview";
import { useAppSelector } from "src/store/hooks";
import { routes, Route as RouteType } from "./routes";

const authenticatedRoutes = getKeys(routes)
  .map((route) => routes[route])
  .filter((r) => r.requiredAuth ?? true)
  .map((r) => r.path);

export function DefaultRouter() {
  const isAuthenticated = useAppSelector((store) => store.login.authenticated);
  const isWebview = useIsWebview();

  // eslint-disable-next-line
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated && authenticatedRoutes.includes(location.pathname)) {
      window.location.hash = location.pathname;
    }
    if (isAuthenticated && location.hash !== "") {
      navigate(location.hash.replace("#", ""));
      window.location.hash = "";
    }
  }, [isAuthenticated, location.pathname, location.hash, navigate]);

  return (
    <>
      {isAuthenticated && !isWebview && <Breadcrumbs />}
      <React.Suspense
        fallback={<LoadingAlert text={"Buscando informações da página"} />}
      >
        <Routes>
          {getKeys(routes).map((name) => {
            const route = routes[name];
            const requiredAuth = route.requiredAuth ?? true;

            return (
              <Route
                key={name}
                path={route.path}
                element={
                  requiredAuth && !isAuthenticated ? (
                    <Navigate replace to="/login" />
                  ) : !requiredAuth && isAuthenticated ? (
                    <Navigate replace to="/" />
                  ) : (
                    <TypedRoute route={route} />
                  )
                }
              />
            );
          })}

          {/* no match route */}
          {/*https://reactrouter.com/docs/en/v6/getting-started/tutorial#adding-a-no-match-route*/}
          <Route
            path="*"
            element={
              isAuthenticated ? (
                <Navigate replace to={"/"} />
              ) : (
                <Navigate replace to={"/login"} />
              )
            }
          />
        </Routes>
      </React.Suspense>
    </>
  );
}

function TypedRoute({ route }: { route: RouteType<any, any> }) {
  // There is no way we can ensure type-safety when extracting the data passed from one page to another (at
  // least not without a zod-like schema for each route...). The way we ensure type-safety is done via only allowing the
  // navigate function provided by the useAppNavigation() hook to be called with correct arguments.
  const state: unknown | null = useLocation().state;

  const urlParams = useParams();
  const urlProps = route.fromPath(urlParams);

  return (
    <route.lazyPage
      urlProps={urlProps}
      componentProps={state !== null ? state : undefined}
    />
  );
}

function Breadcrumbs() {
  const breadcrumbs = useBreadcrumbs();

  return (
    <>
      {breadcrumbs.length > 1 && (
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            {breadcrumbs.map((b) => (
              <li key={b.link} className="breadcrumb-item">
                <Link to={b.link}>{b.route.title}</Link>
              </li>
            ))}
          </ol>
        </nav>
      )}
    </>
  );
}
