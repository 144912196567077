import s from "@mobiltracker/schema-validation";

export const DriverSchema = s
  .object({
    userId: s.string(),
    name: s.string(),
    phoneNumber: s.string().nullable(),
    whatsAppContact: s.string().nullable(),
    vanId: s.number(),
    vanName: s.string(),
    email: s.string().nullable(),
  })
  .transform((driver) => {
    const { userId, ...rest } = driver;
    return { id: userId, ...rest };
  });

export const driverResponseSchema = s.object({
  driverId: s.string(),
  name: s.string(),
  phoneNumber: s.string().nullable(),
  whatsAppContact: s.string().nullable(),
  email: s.string(),
  vanName: s.string(),
});
