import s from "@mobiltracker/schema-validation";

export const GetSchoolsResponseSchema = s
  .object({
    name: s.string(),
    schoolId: s.number(),
    address: s.string().nullable(),
    latitude: s.number().nullable(),
    longitude: s.number().nullable(),
  })
  .transform((s) => ({
    name: s.name,
    id: s.schoolId, // Rename schoolId to id
    address: s.address,
    latitude: s.latitude,
    longitude: s.longitude,
  }))
  .array();
