import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Footer } from "./components/Footer/Footer";
import { useIsWebview } from "./hooks/useIsWebview";
import { Navbar } from "./infrastructure/Navbar/Navbar";
import { DefaultRouter } from "./infrastructure/Router";
import { useAppSelector } from "./store/hooks";

function Main() {
  const isAuthenticated = useAppSelector((s) => s.login.authenticated);
  const isWebview = useIsWebview();

  return (
    <BrowserRouter>
      {isAuthenticated && !isWebview && <Navbar />}
      <br />
      <div className="container">
        <DefaultRouter />
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default Main;
